import comingSoonImg from "../../images/soon.jpg"
import standardTempImg from "../../images/resume-standard.jpg";
import euroPassImg from "../../images/2.jpg";

const carouselData = [
    {
        title: "Standard",
        image: standardTempImg
    },
    {
        title: "Euro Pass",
        image: euroPassImg
    },
    // {
    //     title: "Auckland",
    //     image: comingSoonImg
    // },
    // {
    //     title: "Flying Fish",
    //     image: comingSoonImg
    // },
    // {
    //     title: "Water Train",
    //     image: comingSoonImg
    // },
    // {
    //     title: "Sinking Duck",
    //     image: comingSoonImg
    // }
];

export default carouselData